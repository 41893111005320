<div class="small-container text-size my-0" *transloco="let tev; read 'emailValidation'">
  <mat-card class="center">
    <mat-card-content>
      <h3 class="fs-20">{{tev('youAreAlmostThere') }}</h3>
      <mat-icon class="fs-40 my-4 custom-event">mark_email_unread</mat-icon>
      <p>
        <span [innerHTML]="tev('clickOnTheEmailLink')"></span>
        <span *ngIf="currentProcess === processType.Selling">{{ tev('finishSelling') }}</span>
        <span *ngIf="currentProcess === processType.WaitList">{{ tev('finishSubscription') }}</span>
      </p>
    </mat-card-content>
  </mat-card>

  <div class="icon-text mt-5 mb-1">
    <mat-icon class="custom-event">unsubscribe</mat-icon>
    <span class="icon-text__text fw-700">{{ tev('emailNotReceivedOrLost') }}</span>
  </div>
  <mat-card class="custom-border-left">
    <mat-card-content>
      <p [innerHTML]="tev('checkSpam')"></p>
      <ng-container *ngIf="slowDeliveryDomains?.includes(user?.domainEmail)">
        <br>
        <p [innerHTML]="tev('canTakeTime', {email: user.domainEmail})"></p>
      </ng-container>
      <p class="pt-4" *ngIf="!userService.lastValidationEmailSent; else emailSent">
        {{ tev('ifYouWantANewEmail') }}
        <a (click)="onResendMail()" class="link --custom-event">{{ tev('clickHere') | lowercase }}</a>.
      </p>
      <ng-template #emailSent>
        <p class="pt-4">
          {{ tev('newEmailWellSent') }} ({{ userService.lastValidationEmailSent | date:'medium':'':('language.locale' | transloco) }})
        </p>
      </ng-template>
      <p class="pt-4">
        {{ tev('youHaveEnteredTheAddress') }} <span class="fw-700">{{ user?.email}}</span>.
        {{ tev('aMistake') }} <a (click)="changeAccount()" class="link --custom-event">{{ tev('clickHere') }}</a> {{ tev('toChangeYourAddress') }}
      </p>
    </mat-card-content>
  </mat-card>

  <div class="icon-text mt-5 mb-1">
    <mat-icon class="custom-event">mark_email_read</mat-icon>
    <span class="icon-text__text fw-700">{{ tev('alreadyValidated') }}</span>
  </div>
  <mat-card class="custom-border-left">
    <mat-card-content>
      <p class="">{{ tev('ifNoSelfUpdate') }} <a (click)="reloadUser()" class="link --custom-event">{{ tev('clickHere') | lowercase }}</a>.</p>
    </mat-card-content>
  </mat-card>
</div>
