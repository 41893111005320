// eslint-disable-next-line max-classes-per-file
import { WaitListPurchase } from '.';
import { Category } from './category.model';
import { UserProfile } from './user-profile.model';

export class WaitListGroupStat {
  categoryName?: string;
  categoryId: number;
  readableGroupName?: string;
  quantity: number;
  notifications?: number;
  purchasedTickets?: number;
  groupSize: number;
  groupNumber: number;
}

export type Gauge = {
  [categoryId: number]: {
    currentRank: number,
    randomNotifications: number,
    myRank: number,
  }
}

export const WaitListConfigLabels = {
  reservedTime: 'Durée de la mise en vente privée',
  minGroupTime: 'Temps minimum par groupe',
  minGroupSize: 'Taille minimale de groupe',
  maxPurchaseAutoRegistrations: 'Nombre maximum d\'inscriptions avec achats automatiques',
  maxTicketQuantity: 'Quantité maximale',
  randomFactor: 'Chance de sélection de groupe aléatoire',
  prepayPrice: 'Coût inscription à l\'achat automatique (en cts d\'€ / billet)', // todo
  purchaseAutoEnabled: 'Achat automatique',
  displayGauge: 'Afficher la jauge',
  displayPhone: 'Demander le numéro de téléphone',
  displayPurchaseOptions: 'Afficher les options d\'achat',
  purchaseAutoFactor: 'Chance de sélection d\'une inscription avec achat automatique',
  toNotifyGoal: 'Taux de billets envoyés à un groupe avant de passer au suivant',
  maxUsersToSelect: 'Nombre maximum d\'utilisateurs selectionnés lors d\'une mise en vente (pour email + achat auto)',
  maxUsersToNotify: 'Nombre maximum d\'utilisateurs notifiés lors d\'une mise en vente (pour email + achat auto)',
  hoursBetweenTwoNotificationNotOpened: 'Nombre d\'heures minimum entre deux emails sans ouverture',
  maxNotificationSentPerUser: 'Nombre maximum d\'alertes envoyées à un utilisateur',
};

export class WaitListConfig {
  actionOnExpiredReservation: string;
  activated: boolean;
  createdAt: Date;
  displayGauge: boolean;
  displayGaugeForOrga: boolean;
  displayPhone: boolean;
  displayPurchaseOptions: boolean;
  eventId: number;
  id: number;
  maxPurchaseAutoRegistrations: number;
  maxTicketQuantity: number;
  minGroupSize: number;
  minGroupTime: number;
  payoutAutoTimeOffset: number;
  randomFactor: number;
  reservedTime: number;
  prepayPrice: number; // default is 0. Should set 100 for 1€
  purchaseAutoEnabled: boolean;
  purchaseAutoFactor: number;
  updatedAt: Date;
  toNotifyGoal: number; // 0 -> 100
  maxUsersToSelect: number;
  maxUsersToNotify: number;
  hoursBetweenTwoNotificationNotOpened: number;
  maxNotificationSentPerUser: number;

  constructor(data: Partial<WaitListConfig>) {
    Object.assign(this, data);
  }
}

export const entrySortingOptions = [
  {
    value: 'activated',
    viewValue: 'dashboard.exploreTable.activated',
  },
  {
    value: 'categoryId',
    viewValue: 'dashboard.exploreTable.Category.name',
  },
  {
    value: 'maxPrice',
    viewValue: 'dashboard.exploreTable.maxPrice',
  },
  {
    value: 'quantity',
    viewValue: 'dashboard.exploreTable.quantity',
  },
  {
    value: 'purchasedTicket',
    viewValue: 'dashboard.exploreTable.purchasedTicket',
  },
  {
    value: 'readableGroupName',
    viewValue: 'dashboard.exploreTable.group',
  },
  {
    value: 'createdAt',
    viewValue: 'dashboard.exploreTable.createdAt',
  },
  {
    value: 'commercialCommunication',
    viewValue: 'dashboard.exploreTable.commercialCommunication',
  },
  {
    value: 'notificationCount',
    viewValue: 'dashboard.exploreTable.notificationCount',
  },
  {
    value: 'notificationTotal',
    viewValue: 'dashboard.exploreTable.notificationTotal',
  },
  {
    value: 'UserInfo.WaitListPurchase.purchaseAutoEnabled',
    viewValue: 'dashboard.common.automaticPurchase',
  },
  {
    value: 'UserInfo.WaitListPurchase.prepaidTickets',
    viewValue: 'dashboard.exploreTable.nbPrepaid',
  },
  {
    value: 'UserInfo.WaitListPurchase.purchasedTickets',
    viewValue: 'dashboard.exploreTable.nbPurchasedWithPrepayement',
  },
];

export const readableGroupNameToNumber = (groupName: string) =>
  groupName
    .split('-')
    .map( (n) => n.trim().charCodeAt(0) - 64)
    .reverse() // we want the first element with smallest index
    .reduce( (tot, v, i) => tot + v * (27 ** i) , 0);
// 'A' char code is 65 => we check how much the char is modulo 26 then return the number


export class Entry {
  activated: boolean;
  categoryId: number;
  createdAt?: Date;
  commercialCommunication: boolean;
  customWaitListFields?: any;
  eventId: number;
  id?: number;
  maxPrice: number;
  quantity: number;
  token?: string;
  updatedAt?: Date;
  userInfoId?: number;
  purchasedTicket: number;
  myRank: number;
  groupNumber?: number;
  readableGroupName?: string;
  oldValues?: Partial<Entry> = {};
  notificationCount: number;

  UserProfile?: UserProfile;
  Category?: Category;
  WaitListPurchase?: WaitListPurchase;

  constructor(data: Partial<Entry>) {
    Object.assign(this, data);

    if ((data as any).UserInfo) {
      this.UserProfile = new UserProfile((data as any).UserInfo);
    }

    if ((data as any).category) {
      this.Category = new Category((data as any).category);
    } else if (data.Category) {
      this.Category = new Category(data.Category);
    }
  }

  get noChanges() {
    return this.oldValues
      && Object.keys(this.oldValues).length === 0
      && Object.getPrototypeOf(this.oldValues) === Object.prototype;
  }

}
